import { request } from "./network";

// 获取所有考试信息接口
export function getAllExamData(params) {
  return request({
    method: "get",
    url: "group/getExam",
    params,
  });
}

// // 获取全部监控组列表接口
// export function getGroupAllData(params) {
//   return request({
//     method: "get",
//     url: "/group/selectAll/{examId}",
//     params,
//   });
// }

// 获取监控组 分页查询接口
export function getGroupPageData(params) {
  return request({
    method: "get",
    url: "/group/selectAllWithPage",
    params,
  });
}

// 监控组内人员信息
export function groupSelectPeople(params) {
  return request({
    method: "get",
    url: "/group/selectPeople",
    params,
  });
}
// 单个添加监控组
export function addGroup(data) {
  return request({
    method: "post",
    url: "/group/create",
    data,
  });
}
// 批量添加监控组
export function addGroupBatch(data, ids) {
  return request({
    method: "post",
    url: "/group/creates?ids="+ids,
    data,
  });
}

// 修改监控组
export function updateGroup(params) {
  return request({
    method: "post",
    url: "/group/update/",
    params,
  });
}

// 单个/批量删除监控组
export function deleteGroup(params) {
  return request({
    method: "delete",
    url: "/group/deletes",
    params,
  });
}

// 监控组随机分配考生
export function studentRandom(params) {
  return request({
    method: "post",
    url: "/group/student/random",
    params,
  });
};

// 未分配老师
export function unassignedTeacher(params) {
  return request({
    method: "get",
    url: "/group/teacher/unallocated",
    params,
  });
}

// 未分配学生
export function unassignedStudent(params) {
  return request({
    method: "get",
    url: "/group/student/unallocated",
    params,
  });
}

// 监控组添加考生
export function addGroupStudent(params) {
  return request({
    method: "post",
    url: "group/insertGroupStudent",
    params,
  });
}

// 监控组添加监考老师
export function addGroupTeacher(params) {
  return request({
    method: "post",
    url: "group/insertGroupTeacher",
    params,
  });
}

// 监控组内单个/批量删除学生
export function deleteGroupStudent(params) {
  return request({
    method: "delete",
    url: "/group/student/deletes",
    params,
  });
}

// 监控组内删除老师
export function deleteGroupTeacher(params) {
  return request({
    method: "delete",
    url: "/group/teacher/deletes",
    params,
  });
}
