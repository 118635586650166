<template>
  <div style="display: flex">
    <!-- 侧边目录框 -->
    <el-aside
      :width="showAside ? '3%' : '20%'"
      :style="showAside ? 'transition: width .5s;' : ''"
    >
      <h1 v-if="!showAside">考试列表</h1>
      <div class="aside-input">
        <el-input
          v-model="searchInfo.name"
          placeholder="输入考试名称"
          prefix-icon="el-icon-search"
          v-if="!showAside"
          class="myinput"
          @input="getTeacherExamsLists"
        ></el-input>
      </div>
      <!-- 关闭展开二级菜单的按钮 -->
      <i class="el-icon-menu closeBtn1" @click="collapseStatus()"></i>
      <el-menu class="el-menu-vertical-demo main-menu" :collapse="showAside">
        <!-- 列表区域 -->
        <el-menu-item
          :index="index.toString()"
          v-for="(item, index) in subjectList"
          :key="index"
          @click="
            chooseChecked(item.examId, item.subjectName, index.toString())
          "
          v-if="item.subjectName"
        >
          <span slot="title" style="font-size: 12px"
            >{{ item.name }} + {{ item.subjectName }}</span
          >
        </el-menu-item>
      </el-menu>
    </el-aside>

    <!-- 监考模块 -->
    <el-container class="video-root-box" style="width: 80%; margin-top: 0">
      <el-main>
        <div class="box-header">
          <span style="width: 220px;">
            <el-input
                    v-show="showControl == 0"
                    v-model="videoCondition"
                    placeholder="输入评委姓名"
                    prefix-icon="el-icon-search"
                    class="myInput"
                    @input="searchStudent()"
                    clearable
            ></el-input>
          </span>
          <span>考试名称：{{ examName }}</span>
          <span>场次：{{ subjectName }}</span>
          <span
            >科目：
            <el-select
              v-model="smallSubjectUuid"
              slot="prepend"
              placeholder="请选择"
              @change="changeSmallSubject"
            >
              <el-option
                v-for="(item, index) in selectSubjectLIst"
                :key="index"
                :value="item.smallSubjectUuid"
                :label="item.smallSubjectName"
              ></el-option>
            </el-select>
          </span>
        </div>

        <el-container class="container-box">
          <!-- 视频监控 -->
          <el-main
            v-show="showControl == 0"
            v-loading="topLoading"
            :element-loading-text="topText"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <div class="video-user-box" v-if="studentList.length > 0">
              <div
                v-for="(item, index) in studentList"
                :key="index"
                :class="`${'video-size' + videoSize} ${
                  item.is_check == 0 ? 'user-box' : 'user-boxerror'
                }`"
                :ref="item.teacherId"
              >
                <div
                  class="user-box1"
                  v-for="(itemVideo, indexVideo) in userVideoList"
                  :key="indexVideo"
                  :class="showVideo ? '' : 'showVideo'"
                >
                  <img
                    class="thussat_logo"
                    src="../../../assets/image/logo.svg"
                    alt=""
                  />
                  <div
                    v-if="item.faceStatus == '2' && itemVideo === 'front'"
                    class="isFacePass"
                  >
                    <div>未通过人脸识别</div>
                  </div>
                  <video
                         :ref="
                      'video_' +
                      item.subjectUuid +
                      '_' +
                      item.groupId +
                      '_' +
                      item.teacherId +
                      '_' +
                      item.phone+
                      itemVideo
                    "
                         :key="
                      'video_' +
                      item.subjectUuid +
                      '_' +
                      item.groupId +
                      '_' +
                      item.teacherId +
                      '_' +
                      item.phone+
                      itemVideo
                    "
                         autoplay
                         playsinline
                         v-show="showVideoModel(itemVideo, item)"
                  ></video>
                  <div
                    class="video_action"
                    v-if="
                      item[item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo]
                        .is_join == 1
                    "
                  >
                    <i
                      class="el-icon-chat-dot-round"
                      @click="msgShow(2, item)"
                      v-if="!showVideo && itemVideo!='_screen'"
                    ></i>
                    <template
                      v-if="
                        item[item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo]
                          .is_video == 1 && itemVideo!='_screen'
                      "
                    >
                      <i
                        :class="
                          item[
                            item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo
                          ].is_audio == 1
                            ? 'yd004laba-3'
                            : 'yd004laba-4'
                        "
                        class="ydfont"
                        @click="
                          subscribeAudio(
                            item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo,
                            item
                          )
                        "
                      ></i>
                      <i
                        :class="
                          item[
                            item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo
                          ].is_call == 1
                            ? 'ydmaikefengxianxing'
                            : 'ydmaikefeng-jingyin'
                        "
                        @click="
                          callOneStudent(
                            item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo,
                            item
                          )
                        "
                        class="ydfont"
                      ></i>
                    </template>
                    <i :class="showVideo ? 'ydicon--1' : 'ydicon--'"
                            class="ydfont"
                            @click="
                          showTopVideo(
                            item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo
                          )
                        "
                       v-if="
                        item[item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo]
                          .is_video == 1
                      "
                    ></i>
                  </div>
                  <div
                    class="video_loading"
                    v-show="!showVideo"
                    :element-loading-text="
                      item[item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo]
                        .is_join == 1
                        ? '加载视频中'
                        : '未加入监控'
                    "
                    element-loading-spinner="el-icon-loading"
                    v-loading="
                      item[item.subjectUuid + '_' + item.groupId + '_' + item.teacherId + '_' + item.phone + itemVideo]
                        .is_video == 0
                    "
                  ></div>
                </div>
                <div class="user-info flex">
                  <div class="user_text">
                    <!--<span>编号：</span>
                    <span v-text="item.teacherId"></span>-->
                    <span>评委姓名：</span>
                    <span v-text="item.teacherName"></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 分页器 -->
            <el-pagination
              v-if="total && total > 0"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[3, 6, 9, 12]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-count="totalPage"
            >
            </el-pagination>
            <div class="no-data" v-if="studentList.length == 0">暂无评委</div>
            <div
              v-show="top_video"
              class="top_video"
              :class="showVideo ? '' : 'showVideo'"
            >
              <video
                :id="'top_video_' + userId"
                :key="'top_video_' + userId"
                autoplay
                playsinline
              ></video>
              <div class="video_action" v-if="student[userId].is_join == 1">
                <i
                  :class="
                    student[userId].is_audio == 1
                      ? 'yd004laba-3'
                      : 'yd004laba-4'
                  "
                  class="ydfont"
                  @click="subscribeAudio(student.index, student.key, userId)"
                ></i>
                <i
                  :class="
                    student[userId].is_call == 1
                      ? 'ydmaikefengxianxing'
                      : 'ydmaikefeng-jingyin'
                  "
                  class="ydfont"
                  @click="callOneStudent(student.index, student.key, userId, 2)"
                ></i>
                <i
                  class="ydfont ydicon--1"
                  @click="hideTopVideo(student.index, userId)"
                ></i>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-main>
      <!-- 居家考消息 弹框 -->
      <msgDialog
        :dialogVisibleFu="dialogVisibleFu"
        :allSend="all_send"
        :userKey="userKey"
        @closeDialog="closeDialog"
      />
    </el-container>
  </div>
</template>
<script>
import Header from "c/index/Header";
import { videoMixin } from "mixins/admin/videopw";
import msgDialog from "c/index/examination/msgDialog";

export default {
  name: "examVideoList",
  mixins: [videoMixin],
  components: {
    Header,
    msgDialog,
  },
  data() {
    return {};
  },
  mounted() {},
  created() {},

  methods: {}
};
</script>

<style scoped lang="scss">
@import "a/scss/page";
@import "a/scss/indexList";
@import "a/scss/menuitem";
@import "a/scss/contentheader";
@import "a/scss/table";
@import "a/scss/page";
@import "a/scss/btn";
@import "a/scss/dialog";
.mm div {
  margin-bottom: 20px;
}
.el-button + .el-button {
  margin-left: 0px;
}
.el-button {
  border-color: #fff !important;
}
.el-table td,
.el-table th {
  /* padding: 12px 0; */
  min-width: 0 !important;
  box-sizing: border-box !important;
  text-overflow: ellipsis !important;
  vertical-align: middle !important;
  position: relative !important;
  text-align: left !important;
}
.table th {
  padding: 0 !important;
}
.el-container {
  .el-main {
    padding: 1.5%;
    width: 100%;
    // overflow: hidden;
    .el-container {
      width: 100%;
      margin-top: 1%;
      display: flex;
      flex-flow: row;

      .el-aside {
        width: 300px;
        height: 100%;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        background-color: #fff;
        border-radius: 6px;
        .message-box {
          p {
            text-align: center;
            line-height: 30px;
            height: 30px;
            color: #aaa;
          }
        }
        .aside-header {
          width: 100%;
          // height: 12.5%;
          text-align: center;
          padding: 0 5%;
          > h2 {
            // height: 50%;
            padding: 5% 0;
            // height: 55px;
            // line-height: 55px;
            text-align: center;
            font-size: 18px;
            font-family: "Source Han Sans CN";
            font-weight: 300;
            color: #333333;
          }

          > div {
            height: 40px;
            ::v-deep .el-input {
              position: relative;
              display: block;

              .el-input__inner {
                height: 40px;
                line-height: 40px;
                color: #cccccc;
                background-color: #fafafa;
                border-radius: 6px;
                border: none;
                font-size: 12px;
                font-family: "Source Han Sans CN";
                font-weight: 300;
              }
            }
          }
          clear: both;
        }

        .menu-box {
          flex: 1;
          overflow: auto;
          border-top: 1px solid #f5f5f5;
          margin-top: 6%;
          padding: 6% 0 0 0;
          display: flex;
          flex-flow: column;

          ::v-deep .el-radio-group {
            height: 6%;
            line-height: 6%;
            margin-left: 50px;
            .el-radio-button__inner {
              width: 100px;
              padding: 10% 0;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;

              &:hover {
                color: #56327a;
              }
            }
            .el-radio-button.is-active {
              .el-radio-button__inner {
                color: #fff;
                background-color: #56327a;
                border-color: #56327a;
              }
            }

            .el-checkbox-group {
              margin-left: 50px;
              border-top: 1px solid #f5f5f5;
              padding-top: 18px;
            }
            ::v-deep .el-badge__content.is-fixed {
              position: absolute;
              top: 12px;
              right: 36px;
              border: none;
            }
          }

          .el-menu {
            flex: 1;
            margin: 20px 0 0 0;
            overflow: auto;
            .el-menu-item {
              height: inherit;
              line-height: inherit;
              margin: 5px;
              font-size: 12px;
              font-family: "Source Han Sans CN";
              font-weight: 300;
              color: rgba(0, 0, 0, 0.7);
              padding: 0 10px 0 20px;

              // 单行文本溢出隐藏显示省略号
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:first-child {
                margin-top: 0;
              }

              .item-badge {
                width: 100%;
              }

              .el-row {
                height: 100%;
                padding: 0;

                .el-col-8 {
                  height: inherit;
                  div {
                    float: left;
                    height: 40px;
                    line-height: 40px;
                    padding-left: 4px;
                    margin-top: -2px;
                    .el-link {
                      height: 100%;
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: normal;
                      opacity: 0.7;
                    }
                    &:first-child {
                      .el-link {
                        color: #62affe;
                      }
                    }
                    &:last-child {
                      .el-link {
                        color: #f56c6c;
                      }
                    }
                  }
                }
              }
              ::v-deep .el-badge__content.is-fixed.is-dot {
                position: absolute;
                right: 10px;
                top: 10px;
              }
            }

            .el-menu-item:hover,
            .el-menu-item:hover .el-link,
            .is-active,
            .is-active .el-link {
              background-color: #56327a;
              .el-col-16 {
                color: rgba(255, 255, 255, 0.85) !important;
              }
            }
          }
        }
      }

      .el-main {
        flex: 1;
        padding: 0 0 0 1%;
        display: flex;
        flex-flow: column;

        .video-user-box {
          flex: 1;
          overflow: auto;
          position: relative;

          .user-box {
            &.video-size1 {
              > div {
                width: 100%;
              }
            }

            width: 33%;
            height: 32.8%;
            display: inline-block;
            margin: 0 0.5% 0 0;
            background: #fff;
            border-radius: 6px;
            padding: 0;

            &:nth-child(3n) {
              margin-right: 0;
            }

            > div {
              width: 49%;
              height: 80%;
              display: inline-block;
              position: relative;
              margin-right: 1%;
              border-radius: 6px;
              float: left;

              &.user-info {
                width: 100%;
                font-size: 12px;
                padding: 0 10px;
                margin-right: 0;
                margin-top: 15px;
                font-family: "Source Han Sans CN";
                font-weight: 400;
                color: #333333;
                max-height: 30px;

                /*.user_text {*/
                /*  // width: 50%;*/
                /*  position: relative;*/
                /*  display: inline-block;*/
                /*  &:first-child {*/
                /*    width: 65%;*/
                /*    text-align: left;*/
                /*    > span:nth-child(2) {*/
                /*      margin-right: 5px;*/
                /*    }*/
                /*  }*/
                /*  &:nth-child(2) {*/
                /*    width: 35%;*/
                /*    text-align: right;*/
                /*    > div {*/
                /*      color: #52a1e4;*/
                /*      display: inline-block;*/
                /*      cursor: pointer;*/
                /*      margin-right: 5px;*/
                /*    }*/
                /*    > span {*/
                /*      height: 10px;*/
                /*      width: 46px;*/
                /*      display: inline-block;*/
                /*      position: relative;*/
                /*    }*/
                /*  }*/
                /*}*/
              }
              .text_btn2 {
                color: #52a1e4;
                // height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;
              }

              &.showVideo {
                > video {
                  /*border: 1px dotted #ccc;*/
                }
                ::v-deep .el-loading-mask {
                  border: 1px dotted #ccc;
                }
              }

              > video {
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 6px;
                position: absolute;
                top: 0;
                z-index: 2;
                left: 0;
              }

              .video_action {
                position: absolute;
                top: 8px;
                right: 2px;
                z-index: 3;

                .ydfont,
                .el-icon-chat-dot-round,
                .el-icon-top {
                  padding: 2px;
                  margin-right: 2px;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  text-align: center;
                  background: #ffffff;
                  border-radius: 50%;
                  color: #563279;
                  font-size: 20px;
                  font-weight: bold;
                }
              }

              .video_loading {
                position: absolute !important;
                top: 1px;
                height: 98%;
                z-index: 1;
                width: 98%;
                left: 1px;
                background-color: #ccc;
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
            }
          }

          .user-boxerror {
            &.video-size1 {
              > div {
                width: 100%;
              }
            }

            position: absolute;
            width: 45%;
            height: 650px;
            top: 0;
            z-index: 100;
            display: inline-block;
            background: #fff;
            border-radius: 6px;
            padding: 40px 20px 0 20px;
            left: 20%;
            &:nth-child(2n) {
              margin-right: 0;
              margin-left: 0.5%;
            }

            > div {
              &.user-check_close {
                width: 28px;
                height: 28px;
                position: absolute;
                right: -3px;
                top: 0;
                color: #56327a;
                font-size: 28px;
                z-index: 9;
                cursor: pointer;
              }

              width: 49%;
              height: 80%;
              display: inline-block;
              position: relative;
              margin-right: 1%;
              border-radius: 6px;
              float: left;

              &.user-info {
                width: 100%;
                font-size: 12px;
                padding: 0 10px;
                margin-right: 0;
                margin-top: 15px;
                font-family: "Source Han Sans CN";
                font-weight: 400;
                color: #333333;
                max-height: 30px;

                .user_text {
                  width: 50%;
                  position: relative;
                  display: inline-block;
                  &:first-child {
                    text-align: left;
                  }
                  &:nth-child(2) {
                    text-align: right;
                    > div {
                      color: #52a1e4;
                      display: inline-block;
                      cursor: pointer;
                      margin-right: 5px;
                    }
                    > span {
                      height: 10px;
                      width: 40px;
                      display: inline-block;
                      position: relative;
                    }
                  }
                }
              }
              .text_btn2 {
                color: #52a1e4;
                // height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;
              }

              &.showVideo {
                > video {
                  border: 1px dotted #ccc;
                }
              }

              > video {
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 6px;
                position: absolute;
                top: 0;
                z-index: 2;
                left: 0;
              }

              .video_action {
                position: absolute;
                top: 8px;
                right: 2px;
                z-index: 3;

                .ydfont,
                .el-icon-chat-dot-round,
                .el-icon-top {
                  padding: 2px;
                  margin-right: 2px;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  text-align: center;
                  background: #ffffff;
                  border-radius: 50%;
                  color: #563279;
                  font-size: 20px;
                  font-weight: bold;
                }
              }

              .video_loading {
                position: absolute !important;
                top: 1px;
                height: 98%;
                z-index: 1;
                width: 98%;
                left: 1px;
                background-color: #ccc;
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
            }
          }
        }

        .el-pagination {
          padding: 1.5% 10px;
          margin-top: 0;
        }
      }
      .menu-box {
        .no-data {
          font-size: 18px;
        }
      }
      .no-data {
        text-align: center;
        font-size: 28px;
        color: #aaa;
        line-height: 400px;
      }
    }
  }
}
.container-box {
  position: relative !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}
.top_video {
  > video {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 6px;
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    background: #000;
  }
  .video_action {
    position: absolute;
    top: 8px;
    right: 2px;
    z-index: 3;
    .ydfont {
      padding: 2px;
      margin-right: 2px;
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #ffffff;
      border-radius: 50%;
      color: #563279;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
::v-deep .el-table .student_name {
  cursor: pointer;
}

.thussat_logo {
  width: 20%;
  position: absolute;
  z-index: 99;
}
</style>
