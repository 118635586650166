import { request } from "./network";

// 获取考试数据列表接口
export function getExamData(params) {
    return request({
        method: "get",
        url: "exam/lists",
        params,
    });
}
//后台获取数据
export function getStudentsAlic(teacherId,subjectId, examUuid,condition,startPage = 1,offset = 9) {
    return request({
        method: "post",
        url: "/adminGetStudentsAlic/"+examUuid+'/'+subjectId+'?condition='+condition+'&startPage='+startPage+'&offset='+offset,
        headers: {
            Authorization: "Bearer " + window.localStorage.getItem('admin-token')
        }
    });
}

// 数据监控
// export function getDataMonitor(params) {
//     return request({
//         method: "get",
//         url: "/system/getMonitorDetail",
//         params,
//     });
// }
// 数据监控（美院）
export function getDataMonitor(params) {
    return request({
        method: "get",
        url: "/system/getMonitorDetailMeiYuan",
        params,
    });
}



//数据监控（科目分小类）
export function selectSubject(params) {
    return request({
        method: "get",
        url: "/system/selectBySubjectUuid",
        params,
    });
}
