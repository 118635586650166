//监控人员相关
import 'aliyun-webrtc-sdk';
import configApp from "config/index";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import localForage from "localforage";
import {formatTime} from "common/utils";
import {adminGetJudgesAlic} from "r/admin/examvideopw";
// 数据监控
import {removeUser, recordChannel} from "r/index/videoList";
import {getAllExamData} from "r/admin/group";
import {selectSubject} from "r/admin/examvideo";

const selectOptions = ['所有评委', '消息'];
const studentStartInfo = {
    channelId: '',
    subjectUuid: '',
    groupId: '',
    index: '',
    key: '',
    name: '',
    subjectId: '',
    teacherId: '',
    '0': {
        is_join: 0,
        is_video: 0,
        is_call: 0,
        is_audio: 0,
        check_fps: 0,
        fps_times: 0,
    }
};
export const videoMixin = {
    data() {
        return {
            list: [],
            channelList: [],
            topText: '',
            menuLoading: false,
            menuText: '数据加载中',
            studentList: [],
            setTimeoutPageObj: null,
            setTimeoutPageTime: 0, //自动翻页时间 单位分钟
            subscribeAudioStudentList: {},
            channelArr: [],
            pageSize: 9,
            pageNum: 1,
            videoSize: 0,
            showVideo: false,
            topLoading: false,
            userPageId: 0,
            userId: 0,
            subjectId: 0,
            subjectUuid: 0,
            examUuid: 0,
            messageUnCount: 0,
            top_video: false,
            student: studentStartInfo,
            aliWebrtcArr: [],
            total: 0, // 数据总条数
            totalPage: 1, // 总页数
            all_call: false,
            noMessage: false,
            stomp: {},
            socket: null,
            teacherId: 0,
            messageList: [],
            abnormalMessageList: [],
            userVideoList: [""],
            userVideoListScreen: ["", "_screen"],
            userVideoListOne: [""],
            hasScreenVideo: true,
            topKeyArr: {},
            dialogVisibleFu: false, // 控制发送消息弹框显示与隐藏
            dataInfo: {},
            userKey: '', // 当前发送消息的本地缓存key
            userType: '', // 正、反面监控  front-正面  back-反面
            all_send: 0, // // 发送消息 0-单个 1-全体 2-评委
            msgSingleObj: {}, // 发送的单个消息对象列表
            msgAllList: [], // 发送的全体消息列表
            msgPWTObj: {}, // 发送的评委老师消息列表
            aliWebrtc_tea: {}, //老师aliWebrtc
            isshowMe: false, //是否显示老师自己的摄像头
            imgwidth: 50, //老师自己
            imgheight: 50,

            optionRtc: {
                isReceiveOnly: configApp.rtc.canCameraPublish,
            },
            refreshTimeArr: [1, 1, 2, 2, 2, 4, 4, 4, 4, 8, 8, 8, 8, 8, 16, 16, 16, 16, 16, 16, 20], //重新连接的时间间隔，单位秒，最大的是默认是20s
            refreshSocketTimeout: 0, //自动重连的定时器
            refreshSocket: 0, //自动重连的定时器

            showStudentDialog: false,
            isNowPage: true,

            examlistInfo: {
                phone: "",
                pageNum: 1,
                pageSize: 10,
                name: "",
                examId: "",
                status: "",
                token: "",
            },
            queryInfo: {
                pageNum: 1,
                pageSize: 10,
                status: "",
                examName: "",
            },
            teacherName: "", //监考人显示【0
            teacherPhone: "", //监考人显示【0
            showAside: false,
            examName: "",
            subjectName: "",
            loading: true,
            examId: "",
            searchList: [],
            examInfoList: [],
            subjectList: [],
            searchExamName: "",
            searchStudentName: "",
            isCollapse: false,
            searchInfo: {
                name: "",
            },
            userInfoDta: JSON.parse(window.localStorage.getItem("admin-userInfo")),
            showControl: 0, //视频监控与数据监控切换变量

            dataControlList: [],
            totalNum: 0,
            queryParamsData: {
                pageNum: 1,
                pageSize: 10,
                subjectUuid: null,
                smallSubjectUuid: null,
                teacherId: null,
                condition: null,
                monitorStatus: null,
                startExamStatus: null,
                testStatus: null,
                pictureStatus: null,
                handPaperStatus: null,
                finalEndTime: null,
                faceStatus: null,
            },
            dataCondition: '', //搜索评委
            timer: null, //定时器名称

            smallSubjectUuid: "", //select分科目
            selectSubjectLIst: [], //获取分科目List
            videoCondition: "", //搜索筛选video
            id: "", //补时间参数
            userInfo: window.localStorage.getItem("index-userInfo"),
        };
    },
    created() {
        this.getTeacherExamsLists();
        this.examlistInfo.phone = this.userInfoDta.name;
        this.teacherId = localStorage.getItem('admin-userInfo-name');
    },
    mounted() {
        let aliWebrtc = new AliRtcEngine();
        /**
         * AliWebRTC isSupport检测
         */
        aliWebrtc.enableCamera = configApp.rtc.canCameraPublish;
        aliWebrtc.isSupport(this.optionRtc).then(re => {
            this.initSocket();
        }).catch(error => {
            this.$notify.error({
                title: '错误',
                message: error.message
            });
        })
    },
    methods: {
        showVideoModel(type, item) {
            let status = true;
            if (type == '_screen' && item.screen_active == 0) {
                status = false;
            }
            return status;
        },
        // 获取小科目List
        getSelectSubject() {
            this.queryParamsData.subjectUuid = this.subjectUuid;
            selectSubject(this.queryParamsData).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.selectSubjectLIst = res.data.data;
                this.smallSubjectUuid = this.selectSubjectLIst[0].smallSubjectUuid;
                window.localStorage.setItem(
                    "admin_smallSubjectUuid",
                    this.smallSubjectUuid
                );
                // }
            });
        },
        changeSmallSubject(value) {
            window.localStorage.setItem("admin_smallSubjectUuid", value);
            this.smallSubjectUuid = value;
        },
        // 搜索视频
        searchStudent() {
            this.getList();
        },

        //获取老师监考科目列表
        async getTeacherExamsLists() {
            const data = await getAllExamData(this.searchInfo);
            if (data.status != 200) {
                this.$message({
                    message: res.message,
                    type: "error",
                });
            } else {
                this.examInfoList = data.data;
                this.subjectList = data.data;
            }
        },
        collapseStatus() {
            this.showAside = !this.showAside;
        },
        chooseChecked(examId, subjectName, index) {
            this.examlistInfo.examId = examId;
            this.showAside = !this.showAside;
            this.examName = this.examInfoList[index].name;
            this.subjectName = subjectName;
            this.subjectId = this.examInfoList[index].subjectId;
            this.examUuid = this.examInfoList[index].examId;
            this.subjectUuid = this.examInfoList[index].subjectUuid;
            this.getSelectSubject();
            this.pageNum = 1;
            this.getList();
        },
        //获取当前监控组的所有评委
        async getList() {
            this.topLoading = true;
            this.menuLoading = true;
            this.topText = '数据加载中';
            let start = this.pageNum;
            let end = this.pageSize;
            this.changeStudentVideoList();
            let condition = this.videoCondition;
            let {data} = await adminGetJudgesAlic(this.subjectId, this.examUuid, condition, start, end);
            this.topLoading = false;
            this.menuLoading = false;
            this.topText = '';
            if (data.data && data.data.page && data.data.page.total > 0) {
                this.channelList = JSON.parse(data.data.list);
                this.list = JSON.parse(data.data.list);
                this.total = data.data.page.total;
                if (this.list.length > 0) {
                    this.videoSize = parseInt(this.list[0].videoSize);
                    if (this.hasScreenVideo) {
                        this.videoSize = this.videoSize + 1;
                        this.userVideoList = this.userVideoListScreen;
                    }
                    if (this.videoSize === 1) {
                        this.userVideoList = this.userVideoListOne;
                    }
                }
                this.totalPage = Math.ceil(this.total / this.pageSize);
                this.createRtc();
            }
        },
        //初始化aliwebrtc对象
        createRtc() {
            this.studentList = [];
            let studentList = [];
            let list = this.list;
            let key = 0;
            for (let i = 0; i < list.length; i++) {
                let aliWebrtc = new AliRtcEngine();
                aliWebrtc.configLocalCameraPublish = configApp.rtc.canCameraPublish;
                aliWebrtc.enableAudioVolumeIndicator = false;
                let config = JSON.parse(list[i].token);
                let channelId = config.channelId;
                this.aliWebrtcArr[channelId] = aliWebrtc;
                this.channelArr[channelId] = {
                    is_publish: 0,
                    config: config
                };
                let data = list[i][channelId];
                let tempData = [];
                let userVideoList = this.userVideoList;
                for (let j = 0; j < data.length; j++) {
                    let temp = data[j];
                    let fix = temp.subjectUuid + '_' + temp.groupId + '_' + temp.teacherId + '_' + temp.phone;
                    for (let v = 0; v < userVideoList.length; v++) {
                        temp[fix + userVideoList[v]] = {
                            is_join: 0, //是否入会
                            is_video: 0, //是否有订阅视频流
                            is_call: 0, //是否和评委通话
                            is_audio: 0, //是否听评委声音
                            check_fps: 0,
                            fps_times: 0,
                        };

                    }
                    temp['screen_active'] = 0;
                    temp['key'] = key;
                    temp['topKey'] = 0;
                    temp['abnormal'] = 0;
                    temp['is_check'] = 0;
                    temp['channelId'] = temp.groupId;
                    tempData.push(temp);
                    key = ++key;
                }
                studentList = studentList.concat(tempData);
                //加入房间
                this.joinRoom(channelId, config);
                //监听事件
                try {
                    this.initRtc(channelId);
                } catch (e) {

                }
            }
            this.studentList = studentList;
            this.channelArr = this.removePropertyOfNull(this.channelArr);
            this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
            this.searchList = this.studentList;
            let times = localStorage.getItem("setTimeoutPageTime", this.setTimeoutPageTime + "");
            if (times) {
                this.setTimeoutPageTime = parseInt(times);
            }
            this.setTimeoutPage();
        },
        //自动翻页
        setTimeoutPage() {
            this.setTimeoutPageObj && clearTimeout(this.setTimeoutPageObj);
            this.setTimeoutPageObj = null;
            localStorage.setItem("setTimeoutPageTime", this.setTimeoutPageTime + "");
            if (this.setTimeoutPageTime > 0) {
                this.setTimeoutPageObj = setTimeout(() => {
                    this.setTimeoutPageObj && clearTimeout(this.setTimeoutPageObj);
                    let newNum = this.pageNum;
                    newNum = ++newNum;
                    if (newNum > this.totalPage) {
                        newNum = 1;
                    }
                    this.handleCurrentChange(newNum);
                }, this.setTimeoutPageTime * 60000);
            }
        },
        //aliwebrtc的事件监听
        initRtc(channelId) {
            let aliWebrtc = this.aliWebrtcArr[channelId];
            /**
             * remote用户加入房间 onJoin
             * 更新在线用户列表
             */
            aliWebrtc.on("onJoin", (publisher) => {
                let userId = publisher.userId;
                if (userId) {
                    this.updateUserJoinList(userId); //评委入会更新状态
                }
            });
            /**
             * remote流发布事件 onPublish
             * 将该用户新增到推流列表
             * 若该用户已存在推流列表，则进行状态更新
             */
            aliWebrtc.on("onPublisher", (publisher) => {
                let userId = publisher.userId;
                if (userId) {
                    let userInfo = this.checkUserId(userId);
                    if (userInfo.type == 3) {
                        let streamConfigs = publisher.streamConfigs;
                        let screen_active = 0;
                        streamConfigs.forEach((item) => {
                            if (item.label == 'sophon_video_screen_share' &&
                                item.state == 'active') {
                                screen_active = 1;
                            }
                        })
                        this.subscribeUser(userId, screen_active); //订阅评委流
                    }
                }
            });

            /**
             * remote流结束发布事件 onUnPublisher
             * 推流列表删除该用户
             * 移除用户视图
             * 初始化订阅状态
             */
            aliWebrtc.on("onUnPublisher", (publisher) => {
                let userId = publisher.userId;
                if (userId) {
                    this.unSubscribePublisher(userId); //更新评委订阅的状态
                    this.unPublisherErrMessage(userId); //更新评委监控异常的错误日志
                }
            });

            /**
             * 被服务器踢出或者频道关闭时回调 onBye
             */
            aliWebrtc.on("onBye", (message) => {
                //1:被服务器踢出
                //2:频道关闭
                //3:同一个ID在其他端登录,被服务器踢出
                let msg;
                switch (message.code) {
                    case 1:
                        msg = "被服务器踢出";
                        // 清除本地的 token
                        //window.localStorage.clear();
                        // 使用编程式导航跳转到登录页面
                        //this.$router.push("/login");
                        break;
                    case 2:
                        msg = "频道关闭";
                        break;
                    case 3:
                        msg = "同一个ID在其他端登录,被服务器踢出";
                        break;
                    default:
                        msg = "onBye";
                }
                console.error("onBye", msg);
            });

            /**
             *  错误信息
             */
            aliWebrtc.on("onError", (error) => {
                console.log('onError', error)
                let msg = error && error.message ? error.message : error;
                if (msg && msg.indexOf("no session") > -1) {
                    msg = "请重新登录：" + msg;
                }
                if (error.errorCode === 10011 || error.errorCode === 10012) {
                    msg = error.errorCode === 10011 ? "屏幕共享被禁止" : "屏幕共享已取消";
                }

                if (error.code == 15) {
                    msg = "没有开启H5兼容";
                }
                if (error.type === "publish") {
                    // 提示用户网络状态不佳
                    this.publishAudio(channelId);
                }
                if (error.type === "subscribe") {
                    //先记录当前用户的订阅状态
                    //取消订阅状态
                    let userId = error.userId;
                    if (userId) {
                        let student = this.getPublisher(userId);
                        if (student && student[userId].is_video) {
                            let type = 1;
                            if (this.userId === userId) {
                                type = 2;
                            }
                            this.subscribeUser(userId, student['screen_active'], student, type, !!student[userId].is_audio);
                        }
                    }
                }
            });

            /**
             * 检测到用户离开频道
             * 更新用户列表
             * 移除用户视图
             */
            aliWebrtc.on("onLeave", (publisher) => {
                let userId = publisher.userId;
                this.delSubscribe(userId);
            })

            aliWebrtc.on("onMedia", (data) => {
                let userId = data.userId;
                let student = this.getPublisher(userId);
                if (student && student[data.userId].is_video) {
                    if (data.data.camera && student[userId].check_fps) {
                        //判断当前视频流的fps
                        if (data.data.camera.fps === 0 || data.data.bytesReceivedPerSecond === 0) {
                            let type = 1;
                            if (this.userId === userId) {
                                type = 2;
                            }
                            this.incrementFpsTimes(userId, student);
                            if (student[userId].fps_times > 60) {
                                this.subscribeUser(userId, student['screen_active'], student, type, !!student[data.userId].is_audio);
                            }
                        } else {
                            this.studentList[student['key']][userId].fps_times = 0;
                        }
                    }
                }
            });
        },
        incrementFpsTimes(userId, student) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            if (!student) {
                return false;
            }
            let fps_times = student[userId].fps_times + 1;
            this.studentList[student['key']][userId].fps_times = fps_times;
        },
        //取消订阅状态
        delSubscribe(userId) {
            this.delSubscribeScreen(userId);
            let student = this.getPublisher(userId);
            if (student) {
                let key = student['key'];
                student[userId].is_join = 0;
                student[userId].is_video = 0;
                student[userId].is_call = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                this.studentList[key] = student;
                this.$forceUpdate();
            }
        },
        delSubscribeScreen(userId) {
            let arr = userId.split('_');
            if (!arr[3] || (arr[4] && arr[4] == "screen")) {
                return false;
            }
            let newUserId = userId + "_screen";
            this.delSubscribe(newUserId);
        },
        //清除对象的空对象
        removePropertyOfNull(obj) {
            let _newPar = {};
            for (let key in obj) {
                //如果对象属性的值不为空，就保存该属性（这里我做了限制，如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。
                if ((obj[key] === 0 || obj[key] === false || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
                    _newPar[key] = obj[key];
                }
            }
            return _newPar;
        },
        checkUserId(userId) {
            if (!userId) {
                return false;
            }
            let info = {};
            let userArr = userId.split('_');
            if (userArr.length === 0) {
                return false;
            }
            //监控老师
            if (userArr.length === 2) {
                info.type = 2;
            }
            //面试官
            if (userArr.length === 4) {
                info.type = 3;
            }
            info.userId = userId;
            return info;
        },
        unPublisherErrMessage(userId) {
            let userInfo = this.checkUserId(userId);
            if (userInfo.type === 1) {
                this.abnormalMessageList.push(userInfo);
            }
        },
        // 打开发送消息弹框
        msgShow(type, data = {}, userType = null) {
            let userKey = this.examUuid + "_" + this.subjectUuid + "_" + this.userInfo;
            if (type == 2) {
                // 评委
                this.all_send = 2;
                // userKey += '_' + this.groupToken.userid;
                this.dataInfo = data;
                userKey += '_' + this.dataInfo.teacherId;
                console.log(userKey)
                this.setTimeoutPage();
            }
            this.userKey = userKey;
            this.userType = userType;
            this.dialogVisibleFu = true;
        },
        // 关闭弹框
        closeDialog(value, type, msg, msgType = null) {
            console.log(value, type, msg, msgType)
            this.dialogVisibleFu = value;
            this.setTimeoutPage();
            console.log(123)
            if (type) {
                this.sendAllStudentMessage(msg, msgType);
            }
        },
        //发送给所有评委
        sendAllStudentMessage(msg, msgType) {
            let message = {};
            let userId = "";
            let msgInfo = {};
            message.msg = msg;
            msgInfo.msg = msg;
            if (this.all_send == 2) {
                // 单个评委
                if (msgType) {
                    // 语音
                    message.type = 4;
                    message.audio = msgType;
                } else {
                    message.type = 3;
                }
                message = JSON.stringify(message);
                userId = this.dataInfo.teacherId;
                console.log(userId)
                this.sendMessage('/pubChat', message, [userId], 'tm');
                msgInfo.time = formatTime(new Date());
                if (this.msgPWTObj[this.dataInfo.teacherId]) {
                    this.msgPWTObj[this.dataInfo.teacherId].push(msgInfo);
                } else {
                    this.msgPWTObj[this.dataInfo.teacherId] = [];
                    this.msgPWTObj[this.dataInfo.teacherId].push(msgInfo);
                }
            }
            let dataMessage = {
                "message": msg,
                "time": formatTime(new Date()),
            }
            localForage.getItem(this.userKey).then((list) => {
                if (list) {
                    list.push(dataMessage);
                } else {
                    list = [dataMessage];
                }
                localForage.setItem(this.userKey, list).then(() => {
                });
            });
        },
        /**
         * 和所有评委通话
         * @param call_type 0正常订阅模式 2-取消订阅所有评委
         */
        callAllStudent(call_type = 0) {
            if (call_type === 2) {
                this.all_call = false;
            } else {
                this.all_call = !this.all_call;
                this.setTimeoutPage();
            }
            let is_call = this.all_call ? 1 : 0;
            let studentList = this.studentList;
            let channelArr = {};
            this.subscribeAudioStudentList = {};
            for (let i = 0; i < studentList.length; i++) {
                let student = studentList[i];
                let userId = this.subjectUuid + '_' + student.teacherId;
                let userVideo = this.checkStudentVideo(userId, student, false);
                if (!userVideo) {
                    continue;
                }
                userId = this.subjectUuid + '_' + student.teacherId + '_' + userVideo;
                if (!channelArr[student['channelId']]) {
                    channelArr[student['channelId']] = [];
                    channelArr[student['channelId']][0] = userId;
                } else {
                    channelArr[student['channelId']].push(userId);
                }
                let aliWebrtc = this.aliWebrtcArr[student['channelId']];
                aliWebrtc.muteLocalCamera(this.all_call);
                this.teacherPublishUpStudentCall(userId, student, is_call, false);
            }
            if (channelArr) {
                for (const channelId in channelArr) {
                    if (this.channelArr[channelId].is_publish === 1) {
                        this.sendCallAllSocket(channelId, is_call, channelArr[channelId]);
                    } else {
                        this.publishAudio(channelId, !is_call, (res) => {
                            if (res) {
                                this.sendCallAllSocket(channelId, is_call, channelArr[channelId]);
                            } else {
                                this.$notify.error({
                                    title: '错误',
                                    message: '你的声音发布失败，请刷新重新重试'
                                });
                            }
                        });
                    }
                }
            }
        },
        /**
         * 向所有评委发送通知
         * @param channelId
         * @param type
         * @param receiver
         */
        sendCallAllSocket(channelId, type = 0, receiver = '') {
            let message = {
                channelId: channelId,
                teacherId: this.teacherId,
            };
            message.type = type;
            message = JSON.stringify(message);
            this.sendMessage('/pubChat', message, receiver);
        },
        //和单个评委通话 开启或关闭和评委建立连接
        callOneStudent(userId, student, type = 0) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let is_call = student[userId].is_call;
            if (type === 1 && is_call == 1) {
                return false;
            }
            if (type !== 2) {
                is_call = is_call ? 0 : 1;
            } else {
                if (is_call === 0) {
                    return false;
                }
                is_call = 0;
            }
            let aliWebrtc = this.aliWebrtcArr[student.channelId];
            aliWebrtc.muteLocalCamera(!is_call);
            this.setTimeoutPage();
            if (this.channelArr[student.channelId].is_publish === 1) {
                if (is_call) {
                    this.subscribeAudioStudentList[userId] = 1;
                } else {
                    if (this.subscribeAudioStudentList[userId]) {
                        delete this.subscribeAudioStudentList[userId];
                    }
                }
                this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
                this.teacherPublishUpStudentCall(userId, student, is_call);
            } else {
                this.publishAudio(student.channelId, !is_call, (res) => {
                    if (res) {
                        if (is_call) {
                            this.subscribeAudioStudentList[userId] = 1;
                        } else {
                            if (this.subscribeAudioStudentList[userId]) {
                                delete this.subscribeAudioStudentList[userId];
                            }
                        }
                        this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
                        this.teacherPublishUpStudentCall(userId, student, is_call);
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: '你的声音发布失败，请刷新重新重试'
                        });
                    }
                })
            }
            // let teacherId = this.teacherId+"_"+student.channelId
            // let teacherInfo = aliWebrtc.getUserInfo(teacherId);

        },
        //初始化 webSocket
        initSocket() {
            if (this.refreshSocketTimeout) {
                return true;
            }
            if (typeof (WebSocket) == "undefined") {
                this.$message({
                    showClose: true,
                    message: '您的浏览器不支持WebSocket',
                    type: 'error'
                });
            } else {
                let socket;
                if (!this.socket) {
                    socket = new SockJS(configApp.socket.base_url, null, {
                        timeout: configApp.socket.timeout
                    });
                    this.socket = socket;
                } else {
                    socket = this.socket;
                }
                let stomp = Stomp.over(socket);
                stomp.debug = null;
                this.stomp = stomp;
                // 每隔30秒做一次心跳检测
                stomp.heartbeat.outgoing = configApp.socket.outgoing;
                // 客户端不接收服务器的心跳检测
                stomp.heartbeat.incoming = configApp.socket.incoming;
                const user = {
                    'username': this.teacherId,
                    'token': localStorage.getItem("admin-token"),
                    'type': 't'
                };
                try {
                    //连接
                    stomp.connect(user, (frame) => {
                        this.refreshSocket = 0;
                        this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                        //用户模式
                        stomp.subscribe("/user/" + this.teacherId + "/subChat", (res) => {
                            let data = JSON.parse(res.body);
                            if (data.code == 200) {
                                let message = JSON.parse(data.data.message);
                                message.isRead = 0;
                                message.id = data.data.messageId;
                                let messageType = message.type;
                                switch (messageType) {
                                    case 1:
                                        this.messageUnCount = ++this.messageUnCount;
                                        this.messageList.unshift(message);
                                        this.noMessage = false;
                                        break;
                                    case 2:
                                        let userId = data.data.user.username;
                                        break;
                                }

                                // console.log(this.messageUnCount, "消息条数")
                            }
                        });
                    }, () => {
                        this.refreshSocket = ++this.refreshSocket;
                        let refreshTimeArr = this.refreshTimeArr;
                        let time = refreshTimeArr[this.refreshSocket - 1];
                        time = time ? time : refreshTimeArr[refreshTimeArr.length - 1];
                        this.refreshSocketTimeout = setTimeout(async () => {
                            this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                            this.refreshSocketTimeout = null;
                            this.socket = null;
                            this.initSocket();
                        }, time * 1000);
                    });
                } catch (e) {
                }
            }
        },
        checkStudentVideo(userid, student = null, is_alert = true) {
            if (!student) {
                student = this.getPublisher(userid);
            }
            if (!student || !student.name) {
                is_alert && this.$notify.error({
                    title: '错误',
                    message: '视频未开启连接'
                });
                return false;
            }
            let is_join = 0;
            let video = '';
            let userVideoList = this.userVideoList;
            for (let i = 0; i < userVideoList.length; i++) {
                let userVideo = userVideoList[i];
                if (student[this.subjectUuid + '_' + student.teacherId + '_' + userVideo].is_join == 1) {
                    is_join = 1;
                    video = userVideo;
                    break;
                }
            }
            if (is_join == 0) {
                is_alert && this.$notify.error({
                    title: '错误',
                    message: student.name + '视频未开启连接'
                });
                return is_join;
            }
            return video;
        },
        //发送Socket 消息
        sendMessage(cmd, message, receiver = '') {
            let data = {
                "message": message
            };
            if (receiver) {
                data.receiver = receiver;
            }
            data.subjectUuid = this.subjectUuid;
            data = JSON.stringify(data);
            this.stomp.send(cmd, {}, data);
        },
        //视频缩小事件
        hideTopVideo() {
            if (window.document.exitFullscreen) {
                window.document.exitFullscreen().then((r) => {

                }).catch((err) => {
                    this.top_video = true;
                    this.showVideo = true;
                });
            } else if (window.document.mozCancelFullScreen) {
                window.document.mozCancelFullScreen();
            } else if (window.document.webkitCancelFullScreen) {
                window.document.webkitCancelFullScreen();
            } else if (window.document.msExitFullscreen) {
                window.document.msExitFullscreen();
            }

        },
        // 控制每页显示几条数据
        handleSizeChange(newSize) {
            this.pageSize = newSize;
            this.setTimeoutPage();
            this.getList();
        },
        // 控制显示第几页
        handleCurrentChange(newNum) {
            this.pageNum = newNum;
            this.getList();
        },
        //页面改动重新订阅评委的音视频
        changeStudentVideoList() {
            //取消订阅当前页面评委的视频流
            if (this.studentList) {
                let list = this.studentList;
                for (let i = 0; i < list.length; i++) {
                    let student = list[i];
                    this.aliWebrtcArr[student.channelId].leaveChannel().then((res) => {
                    }, (error) => {
                    });
                }
                this.studentList = [];
            }
        },
        unSubscribeUser(userId, student = false) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            if (student && student[userId].is_video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                aliWebrtc.configRemoteCameraTrack(userId, false, false);
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                aliWebrtc.configRemoteAudio(userId, false);
                aliWebrtc.setAudioVolume(userId, 1);
                aliWebrtc.subscribe(userId).then(res => {
                }).catch((error) => {
                    console.log(error, "userId:" + userId, "channelId:" + student['channelId'], "unSubscribeUser");
                })
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                this.studentList[student['key']][userId] = student[userId];
            }
        },
        //视频列表 视频放大事件
        showTopVideo(userId, type = 1) {
            this.userId = userId;
            let video;
            let studnet = null;
            if (type === 1) {
                this.userPageId = userId;
                this.userId = 0;
                this.student = studentStartInfo;
                video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0].parentNode : false;
                studnet = this.getPublisher(userId);
            } else {
                this.topLoading = false;
                this.topText = '';
                video = this.$refs['top_video_' + userId] ? this.$refs['top_video_' + userId].parentNode : false;
            }
            if (this.showVideo) {
                this.imgwidth = 50;
                if (window.document.exitFullscreen) {
                    window.document.exitFullscreen();

                } else if (window.document.mozCancelFullScreen) {
                    window.document.mozCancelFullScreen();

                } else if (window.document.webkitCancelFullScreen) {
                    window.document.webkitCancelFullScreen();

                } else if (window.document.msExitFullscreen) {
                    window.document.msExitFullscreen();
                }

                // if (!!document.fullscreen ||
                //   document.mozFullScreen ||
                //   document.webkitIsFullScreen ||
                //   document.webkitFullScreen ||
                //   document.msFullScreen
                // ) {
                //   this.imgwidth = 50
                // }
            } else {
                this.imgwidth = 250
                try {
                    if (video) {
                        if (video.requestFullscreen) {
                            video.requestFullscreen();
                            // this.imgwidth = 50
                        } else if (video.msRequestFullscreen) {
                            video.msRequestFullscreen();
                            // this.imgwidth = 50
                        } else if (video.mozRequestFullScreen) {
                            video.mozRequestFullScreen();
                            // this.imgwidth = 50
                        } else if (video.webkitRequestFullscreen) {
                            video.webkitRequestFullscreen();
                            // this.imgwidth = 50
                        }

                    } else {
                        this.imgwidth = 50
                    }

                } catch (e) {

                }
            }
        },
        //监听视频放大缩小改变的事件
        fullScreenChangeHandler() {
            this.showVideo = !this.showVideo;
            this.setTimeoutPage();

            if (this.userId !== 0) {
                //非本页面评委 放大视频和缩小视频监控
                if (this.showVideo === false) {
                    //断流 关闭声音和通话
                    let student = this.student;
                    let userId = this.userId;
                    this.unSubscribeUser(userId, student);
                    this.top_video = false;
                    this.showVideo = false;
                    this.teacherPublishUpStudentCall(userId, student, 0);
                } else {
                    this.top_video = true;
                }
            } else {
                let userId = this.userPageId;
                this.top_video = false;
                this.student = studentStartInfo;
                this.userId = 0;
                if (this.showVideo === false) {
                    this.subscribeAudio(userId, false, false, 2);
                    this.callOneStudent(userId, false, 2);
                }
            }
        },
        //评委加入频道 更新评委信息
        updateUserJoinList(userId) {
            let student = this.getPublisher(userId);
            if (student && student[userId].is_join == 0) {
                student[userId].is_join = 1;
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                let key = student['key'];
                this.studentList[key][userId] = student[userId];

                this.$forceUpdate();
            }
        },
        //取消订阅音频状态
        unSubscribeAudio(userId, student = false) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let examUuid = localStorage.getItem('index-examId');
            // removeUser(this.subjectUuid,examUuid,student.channelId,userId);
            if (student && student[userId].is_video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                student[userId].is_audio = 0;
                this.studentList[student['key']][userId] = student[userId];
            }
        },
        //订阅用户的音频
        subscribeAudio(userId, student = false, has_subscribeAudio = false, type = 1) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let key = student['key'];
            let is_audio = this.studentList[key][userId].is_audio;
            if (has_subscribeAudio && is_audio == 1) {
                return true;
            }
            if (type === 1) {
                is_audio = is_audio ? 0 : 1;
            } else {
                if (is_audio === 0) {
                    return true;
                }
                is_audio = 0;
            }
            let aliWebrtc = this.aliWebrtcArr[student.channelId];
            aliWebrtc.muteRemoteAudioPlaying(userId, !is_audio);
            aliWebrtc.setAudioVolume(userId, 1);
            this.studentList[key][userId].is_audio = is_audio;
            this.$forceUpdate();
        },
        /**
         * 加入房间
         * 触发：输入房间号、单击加入房间按钮
         * 更新页面信息
         * 默认开启预览
         * 获取鉴权信息
         * 加入房间
         * 本地默认自动推视频流（视频流 + 音频流）
         * 发布本地流
         */
        joinRoom(channelId, config = null) {
            let aliWebrtc = this.aliWebrtcArr[channelId];
            let userName = '监控老师_' + this.teacherId;
            //2. 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
            let authInfo = {};
            if (!config) {
                config = this.channelArr[channelId].config
            }
            authInfo.appid = config.appid;
            authInfo.userid = config.userid;
            authInfo.timestamp = config.timestamp;
            authInfo.nonce = config.nonce;
            authInfo.token = config.token;
            authInfo.gslb = config.gslb;
            authInfo.channel = config.channelId;
            aliWebrtc.joinChannel(authInfo, userName).then(() => {
                this.publishAudio(channelId);
            }).catch((error) => {
                // console.log("[监控加入房间失败]" + error.message);
            })
        },
        publishAudio(channelId, mute = true, cb = null) {
            let aa = setTimeout(() => {
                aa && clearTimeout(aa);
                let aliWebrtc = this.aliWebrtcArr[channelId];
                aliWebrtc.configLocalAudioPublish = true;
                aliWebrtc.configLocalCameraPublish = false;
                aliWebrtc.configLocalScreenPublish = false;
                aliWebrtc.enableCamera = false; //设置是否允许使用摄像头
                aliWebrtc.publish().then((res) => {
                    this.channelArr[channelId].is_publish = 1;
                    cb && cb(true);
                }).catch((error) => {
                    this.channelArr[channelId].is_publish = 0;
                    cb && cb(false);
                });
            }, 100)

        },
        /**
         * 老师发布或关闭音频流 更新评委状态和发送socket
         * @param userId 评委id
         * @param student 评委信息
         * @param is_call 是否请求通话 0-关闭通话 1-请求通话
         * @param send_socket 是否发送socket
         */
        teacherPublishUpStudentCall(userId, student, is_call, send_socket = true) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let channelId = student['channelId'];
            let aliWebrtc = this.aliWebrtcArr[channelId];
            aliWebrtc.muteLocalCamera(!is_call);
            let key = student['key'];
            this.studentList[key][userId].is_call = is_call;
            if (send_socket) {
                let message = {
                    channelId: channelId,
                    teacherId: this.teacherId,
                };
                message.type = is_call;
                message = JSON.stringify(message);
                this.sendMessage('/pubChat', message, [userId]);
            }
            this.$forceUpdate();
        },
        //频道老师是否订阅
        /**
         * 频道老师是否订阅
         * @param userId 用户id
         * @param student 用户信息
         * @param type 类型1-当前页面的评委 2-非当前页面的评委
         * @param has_subscribeAudio
         * @returns {boolean}
         */
        subscribeUser(userId, screen_active = false, student = false, type = 1, has_subscribeAudio = false) {
            if (!student) {
                student = this.getPagePublisher(userId);
            }
            if (!student) {
                return false;
            }
            this.subscribeScreenVideo(userId, screen_active, student, type, has_subscribeAudio)
            if (student[userId].is_video) {
                return false;
            }
            let video;
            if (type === 1) {
                this.userId = 0;
                this.student = studentStartInfo;
                video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0] : false;
            } else {
                video = this.$refs['top_video_' + userId] ? this.$refs['top_video_' + userId] : false;
            }
            if (video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                //前置摄像头
                aliWebrtc.configRemoteCameraTrack(userId, true, true);
                if (this.hasScreenVideo) {
                    aliWebrtc.configRemoteScreenTrack(userId, true);
                }
                aliWebrtc.muteRemoteAudioPlaying(userId, !has_subscribeAudio);
                aliWebrtc.configRemoteAudio(userId, true);
                //aliWebrtc.muteAllRemoteAudioPlaying(!has_subscribeAudio);
                aliWebrtc.setAudioVolume(userId, 1);
                this.studentList[student['key']][userId].check_fps = 0;
                this.studentList[student['key']][userId].fps_times = 0;
                aliWebrtc.subscribe(userId).then(res => {
                    aliWebrtc.setDisplayRemoteVideo(
                        userId, // 用户ID
                        video, // html中用于显示stream对象的video元素
                        1 // 1表示摄像头流（大流和小流），2表示屏幕分享流
                    );
                    let top = video.parentNode;
                    if (type === 2) {
                        this.showTopVideo(userId, 2);
                    }
                    if (top) {
                        top.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('msfullscreenchange', this.fullScreenChangeHandler);
                    }
                    student[userId].is_join = 1;
                    student[userId].is_video = 1;
                    student[userId].fps_times = 0;

                    if (has_subscribeAudio) {
                        student[userId].is_audio = 1;
                    }
                    this.studentList[student['key']][userId] = student[userId];
                    let setTimeoutIndex = setTimeout(() => {
                        setTimeoutIndex = clearTimeout(setTimeoutIndex);
                        student[userId].check_fps = 1;
                        this.studentList[student['key']][userId] = student[userId];
                    }, 20000)
                    this.$forceUpdate();
                }).catch((error) => {
                    this.updateUserJoinListFail(userId)
                    console.log(error, "userId:" + userId, "channelId:" + student['channelId'], "subscribeUser");
                })
            }
        },
        // 订阅屏幕流
        subscribeScreenVideo(userId, screen_active, student = false, type = 1, has_subscribeAudio = false) {
            let newUserId = userId + "_screen";
            this.studentList[student['key']]['screen_active'] = screen_active;
            if (screen_active != 1) {
                this.updateUserJoinListFail(newUserId)
                return false;
            }
            if (student[newUserId].is_video) {
                return false;
            }
            let video;
            if (type === 1) {
                this.userId = 0;
                this.student = studentStartInfo;
                video = this.$refs['video_' + newUserId] ? this.$refs['video_' + newUserId][0] : false;
            } else {
                video = this.$refs['top_video_' + newUserId] ? this.$refs['top_video_' + newUserId] : false;
            }
            if (video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                //前置摄像头
                aliWebrtc.configRemoteScreenTrack(userId, true);
                aliWebrtc.muteRemoteAudioPlaying(userId, !has_subscribeAudio);
                aliWebrtc.configRemoteAudio(userId, true);
                //aliWebrtc.muteAllRemoteAudioPlaying(!has_subscribeAudio);
                aliWebrtc.setAudioVolume(userId, 1);
                this.studentList[student['key']][newUserId].check_fps = 0;
                this.studentList[student['key']][newUserId].fps_times = 0;
                aliWebrtc.subscribe(userId).then(res => {
                    aliWebrtc.setDisplayRemoteVideo(
                        userId, // 用户ID
                        video, // html中用于显示stream对象的video元素
                        2 // 1表示摄像头流（大流和小流），2表示屏幕分享流
                    );
                    let top = video.parentNode;
                    if (type === 2) {
                        this.showTopVideo(newUserId, 2);
                    }
                    if (top) {
                        top.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('msfullscreenchange', this.fullScreenChangeHandler);
                    }
                    student[newUserId].is_join = 1;
                    student[newUserId].is_video = 1;
                    student[newUserId].fps_times = 0;

                    if (has_subscribeAudio) {
                        student[newUserId].is_audio = 1;
                    }
                    this.studentList[student['key']][newUserId] = student[newUserId];
                    let setTimeoutIndex = setTimeout(() => {
                        setTimeoutIndex = clearTimeout(setTimeoutIndex);
                        student[userId].check_fps = 1;
                        this.studentList[student['key']][newUserId] = student[newUserId];
                    }, 20000)
                    this.$forceUpdate();
                }).catch((error) => {
                    this.updateUserJoinListFail(newUserId)
                    console.log(error, "userId:" + userId, "channelId:" + student['channelId'], "subscribeUser");
                })
            }
        },
        //评委加入频道-失败 更新评委信息
        updateUserJoinListFail(userId) {
            let student = this.getPublisher(userId);
            if (student && student[userId].is_join == 1) {
                student[userId].is_join = 0;
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                let key = student['key'];
                this.studentList[key][userId] = student[userId];
                this.$forceUpdate();
            }
        },
        //判断和那些评委正在通话恢复通话
        checkHasCallUser(userId) {
            if (this.all_call === false) {
                if (!this.subscribeAudioStudentList[userId]) {
                    return false;
                }
            }
            let student = this.getPublisher(userId);
            if (!student) {
                return false;
            }
            this.callOneStudent(userId, student, 1);
        },
        updateUserAbnormal(userId, student = null) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            if (student) {
                let abnormal = ++student.abnormal;
                student.abnormal = abnormal > this.videoSize ? this.videoSize : abnormal;
                this.studentList[student['key']] = student;
                this.$forceUpdate();
            }
        },
        //获取该监控组下某个评委的信息
        getPublisher(userId) {
            let studentList = this.studentList;
            let student = false;
            let teacherId = userId.split('_')[2];
            for (let i = 0; i < studentList.length; i++) {
                if (studentList[i].teacherId === teacherId) {
                    return studentList[i];
                }
            }
            return student;
        },
        //获取当前分页下某个评委的信息
        getPagePublisher(userId) {
            let studentList = this.studentList;
            let student = false;
            let teacherId = userId.split('_')[2];
            for (let i = 0; i < studentList.length; i++) {
                if (studentList[i].teacherId === teacherId) {
                    return studentList[i];
                }
            }
            return student;
        },
        unSubscribeScreenVideo(userId) {
            let arr = userId.split('_');
            if (!arr[3] || (arr[4] && arr[4] == "screen")) {
                return false;
            }
            let newUserId = userId + "_screen";
            this.unSubscribePublisher(newUserId);
        },
        //用户掉线取消订阅
        unSubscribePublisher(userId) {
            this.unSubscribeScreenVideo(userId);
            let student = this.getPublisher(userId);
            if (student) {
                student[userId].is_join = 1;
                student[userId].is_video = 0;
                student[userId].is_call = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                let abnormal = --student.abnormal;
                student.abnormal = abnormal < 0 ? 0 : abnormal;
                this.studentList[student['key']][userId] = student[userId];
                this.$forceUpdate();
            }
        },
    },
    destroyed() {
        for (const index in this.aliWebrtcArr) {
            this.aliWebrtcArr[index].leaveChannel().then(() => {
                this.aliWebrtcArr[index] = null;
            }, (error) => {
            });
        }
        this.socket && this.socket.close();
    }

};
