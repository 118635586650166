import {request} from "./network";

//后台获取数据
export function adminGetJudgesAlic(subjectId, examUuid, condition, startPage = 1, offset = 9) {
    return request({
        method: "post",
        url: "/adminGetJudgesAlic/" + examUuid + '/' + subjectId + '?condition=' + condition + '&startPage=' + startPage + '&offset=' + offset,
        headers: {
            Authorization: "Bearer " + window.localStorage.getItem('admin-token')
        }
    });
}
